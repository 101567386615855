import { graphql } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import LostAndFound from "../../components/LostAndFound/LostAndFound"
import { SEO } from "../../components/SEO"
import { LostAndFoundPageData, TemplateProps } from "../templates"
import { getStringValuesArray, mapValuesToObject } from "../../helpers.js"
import ImageHero from "../../components/Hero/ImageHero"
import { H1 } from "../../components/Typography"
import { LanguageContext } from "../../context/Language"
import TranslateClient from "../../clients/TranslateClient"

const LostAndFoundTemplate: React.FC<TemplateProps<LostAndFoundPageData>> = ({
  data,
  location,
}) => {
  const { title, content, featuredImage, template } = data.wpPage
  const {
    contactSectionTitle,
    contactText,
    lostAndFoundCtaLink,
    lostAndFoundText,
    lostAndFoundTextTwo,
    lostAndFoundTitle,
  } = template.lostAndFoundPage.lostAndFoundSection

  const [text, setText] = useState({
    title,
    content,
    contactSectionTitle,
    contactText,
    lostAndFoundCtaLink,
    lostAndFoundText,
    lostAndFoundTextTwo,
    lostAndFoundTitle,
  })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({
        title,
        content,
        contactSectionTitle,
        contactText,
        lostAndFoundCtaLink,
        lostAndFoundText,
        lostAndFoundTextTwo,
        lostAndFoundTitle,
      })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([
          title,
          content,
          contactSectionTitle,
          contactText,
          lostAndFoundText,
          lostAndFoundTextTwo,
          lostAndFoundTitle,
        ])
        console.log("Text to Translate:", textToTranslate)

        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )

        if (
          !Array.isArray(translations) ||
          translations.length !== textToTranslate.length
        ) {
          console.error("Invalid translations response:", translations)
          return
        }

        console.log("Translations:", translations)

        const translated = mapValuesToObject(
          {
            title,
            content,
            contactSectionTitle,
            contactText,
            lostAndFoundText,
            lostAndFoundTextTwo,
            lostAndFoundTitle,
          },
          translations
        )

        console.log("Translated Object:", translated)

        // Removing extra spaces and inaccurate content that is due to a google translate bug.
        // https://issuetracker.google.com/issues/119256504
        const correctedContent = translated?.content
          .replaceAll("< ", "<")
          .replaceAll("inline-t ", "inline-t")
          .replaceAll(" >", ">")
          .replaceAll("</ ", "</")
          .replaceAll(" td>", "</td>")
          .replaceAll("figur ", "figure")
          .replaceAll("figura", "figure")
          .replaceAll("figurecl", "figure")
          .replaceAll("<figure cl ", "<figure ")
          .replaceAll("clase=", "class=")
          .replaceAll("tabla", "table")
          .replaceAll(" ass", " class")
          .replaceAll(
            "wp-block-t fähige Inline-Tabelle",
            "wp-block-table inline-table"
          )
          .replaceAll("wp-block-t内联表", "wp-block-table inline-table")
          .replaceAll("wp-block-t內聯表", "wp-block-table inline-table")

        setText({
          title: translated?.title || title,
          content: correctedContent || content,
          contactSectionTitle:
            translated?.contactSectionTitle || contactSectionTitle,
          contactText: translated?.contactText || contactText,
          lostAndFoundCtaLink:
            translated?.lostAndFoundCtaLink || lostAndFoundCtaLink,
          lostAndFoundText: translated?.lostAndFoundText || lostAndFoundText,
          lostAndFoundTextTwo:
            translated?.lostAndFoundTextTwo || lostAndFoundTextTwo,
          lostAndFoundTitle: translated?.lostAndFoundTitle || lostAndFoundTitle,
        })
      })()
    }
  }, [
    language,
    title,
    content,
    contactSectionTitle,
    contactText,
    lostAndFoundCtaLink,
    lostAndFoundText,
    lostAndFoundTextTwo,
    lostAndFoundTitle,
  ])

  return (
    <div className="page-content">
      <SEO url={location.href} title={title} />
      <ImageHero image={featuredImage} overlay={false}>
        <div css={tw`text-center text-white container m-auto p-4 md:p-8`}>
          <H1
            css={[
              tw`text-4xl font-extrabold sm:text-5xl xl:text-6xl`,
              "text-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);",
            ]}
          >
            {text?.title}
          </H1>
        </div>
      </ImageHero>

      <LostAndFound
        contactSectionTitle={contactSectionTitle}
        contactText={contactText}
        lostAndFoundCtaLink={lostAndFoundCtaLink}
        lostAndFoundText={lostAndFoundText}
        lostAndFoundTextTwo={lostAndFoundTextTwo}
        lostAndFoundTitle={lostAndFoundTitle}
      />
    </div>
  )
}

export default LostAndFoundTemplate

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      featuredImage {
        node {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      template {
        ... on WpTemplate_LostAndFound {
          templateName
          lostAndFoundPage {
            lostAndFoundSection {
              contactSectionTitle
              contactText
              lostAndFoundCtaLink {
                target
                title
                url
              }
              lostAndFoundText
              lostAndFoundTextTwo
              lostAndFoundTitle
            }
          }
        }
      }
    }
  }
`
