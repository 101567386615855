import React from "react"
import tw from "twin.macro"
import Button from "../Button/Button"
import { LostAndFoundProps } from "./LostAndFoundType.d"

const LostAndFound: React.FC<LostAndFoundProps> = ({
  contactSectionTitle,
  contactText,
  lostAndFoundCtaLink,
  lostAndFoundText,
  lostAndFoundTextTwo,
  lostAndFoundTitle,
}) => {
  return (
    <article
      css={[
        tw`text-center px-8 md:px-0 bg-gradient-to-b from-[#96e3ff38] to-white`,
      ]}
    >
      <section
        css={tw`container mx-auto px-4 space-y-4 md:p-8`}
        className="li-styling-supported three-column-support"
      >
        <h2 css={[tw`text-3xl md:text-4xl pt-20 font-extrabold`]}>
          {lostAndFoundTitle}
        </h2>

        <p
          css={[tw`max-w-4xl mx-auto my-6`]}
          dangerouslySetInnerHTML={{ __html: lostAndFoundText }}
        />

        <p
          css={[tw`max-w-4xl mx-auto py-10 text-left [&>ul]:mt-4`]}
          dangerouslySetInnerHTML={{ __html: lostAndFoundTextTwo }}
        />

        <Button
          link={lostAndFoundCtaLink?.url}
          text={lostAndFoundCtaLink?.title}
        />

        <h2 css={[tw`text-3xl md:text-4xl font-extrabold pt-8`]}>
          {contactSectionTitle}
        </h2>

        <p
          css={[tw`max-w-4xl mx-auto`]}
          dangerouslySetInnerHTML={{ __html: contactText }}
        />
      </section>
    </article>
  )
}

export default LostAndFound
